import React, {useState, useEffect} from 'react';
import Header from '../../compos/Header/Header';
import BasicSlider from '../../compos/BasicSlider/BasicSlider';
import Footer from '../../compos/Footer/Footer';
import './Home.css';
import { LoginProps } from '../Login/Login';
import utkarsh from '../../Media/Team/utkarsh.jpeg';
import lalan from '../../Media/Team/lalan_kumar.png';
import lalanAdviser from '../../Media/Team/lalan-advisory.jpg';
import DeepShikha from '../../Media/Team/DeepShikha-managingDirector.png';
import SaurabhAdviser from '../../Media/Team/Saurabh-advisory.png';
import ChandanAdviser from '../../Media/Team/Chandan-advisory.png';
import CCTVImg from '../../Media/Images/Slide_CCTV.jpeg';
import PostFactoImg from '../../Media/Images/post-facto-feature-img.jpeg';
import Sensitive from '../../Media/Images/sensitive.jpg';
import People from '../../Media/Images/people.jpg';
import LadyWithWatch from '../../Media/Images/lady-with-watch-croam-img.jpg';
import GroundReport from '../../Media/Images/ground-report-img.jpg';
import RecordReport from '../../Media/Images/record-and-report-img.jpg';
import LocationAware from '../../Media/Images/locationAware.png';
import Riskometer from '../../Media/Images/riskometer-img.jpg';
import shresth from '../../Media/Team/shresth_ojha.png';
import gopal from '../../Media/Team/gopal.png';
import mayank from '../../Media/Team/mayank.jpeg';
import suraaj from '../../Media/Team/suraaj.jpeg';
import placeholder from '../../Media/Team/placeholder.png';
import simranY from '../../Media/Team/simran_yadav.png';
import ashrut from '../../Media/Team/ashrut_sinha.png';
import AboutUsImage from '../../Media/Images/Breporterpng.jpg';
import { useUser } from '../../UserContext';
import FeatureSlider from '../../compos/FeatureSlider/FeatureSlider';

const Home = () => {
	const { user, setIsAdmin, setIsLoggedIn, set_user  } = useUser();
  const [animationClass, setAnimationClass] = useState('');

  const checkIfAdmin = async (setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
		  const response = await fetch('https://backend.b-reporter.com/api/isAdmin', {
			method: 'GET',
			credentials: 'include',
		  });
	
		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }
	
		  const data = await response.json();
		  
	
		  // Check if the API response indicates the user is an admin
		  setIsAdmin(data);
		} catch (error) {
		  console.error('There was a problem with the fetch operation:', error);
		  setIsAdmin(false);
		}
	  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const profileRequestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Profile request failed');
          }
        })
        .then(result => {
          set_user(result);
          setIsLoggedIn(true);
          checkIfAdmin(setIsAdmin);
        })
        .catch(error => {
          console.log('Error occurred while fetching profile:', error);
          setIsLoggedIn(false);
          set_user(null);
        });
    } else {
      setIsLoggedIn(false);
      set_user(null);
    }
  }, [set_user, setIsLoggedIn, setIsAdmin]);
	return (
		<div className='section-bg'>
			<Header />
			<BasicSlider />
			

    


      

      {/* -------------*******-------------- */}

       {/*feature section*/}
      <div className='feature-scroll-point section-bg'>	 
      <FeatureSlider />
      </div>




			{/* Basic Heading changed */}
      <div className='team-scroll-point'></div>
			<div className='section-header pt-5'>
				<h2>Members</h2>
				<p>
					Our <span>Team</span> Members
				</p>
			</div>

			

			<div className='row justify-content-center px-5 pt-3' style={{marginTop:"-60px"}}>
				<div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '300px' }}>
					<div className='single-person ' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={DeepShikha} alt='...' />
							<a href='' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className=' person-info text-center '>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Managing director
							</h3>
							<h3 className='full-name'>Deepshikha</h3>
						</div>
					</div>
				</div>
				{/* <div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '250px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={SaurabhAdviser} alt='...' />
							<a href='' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Consultant
							</h3>
							<h3 className='full-name'>Saurabh Singh</h3>
						</div>
					</div>
				</div> */}
				{/* <div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '250px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={lalan} alt='...' />
							<a
								href='https://www.linkedin.com/in/lalan-kumar-7a7a7513/?originalSubdomain=sg'
								target='_blank'
								className='icon'
							>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Chairman
							</h3>
							<h3 className='full-name'>Prof. Lalan Kumar</h3>
						</div>
					</div>
				</div> */}
				<div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '300px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={mayank} alt='...' />
							<a href='https://www.linkedin.com/in/mayank-roja-64217322b/' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Developer
							</h3>
							<h3 className='full-name'>Mayank Roja </h3>
						</div>
					</div>
				</div>
				{/* <div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '250px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={ajeet} alt='...' />
							<a href='https://www.linkedin.com/in/ajeetkr/' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Developer
							</h3>
							<h3 className='full-name'>Ajeet Kumar</h3>
						</div>
					</div>
				</div> */}
				{/* <div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '250px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={gopal} alt='...' />
							<a href='https://www.linkedin.com/in/gopal-kumar-jha/' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Designer
							</h3>
							<h3 className='full-name'>Gopal Kumar</h3>
						</div>
					</div>
				</div> */}
				<div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '300px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={utkarsh} alt='...' />
							<a href='https://www.linkedin.com/in/mayank-roja-64217322b/' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Developer
							</h3>
							<h3 className='full-name'>Utkarsh Gupta</h3>
						</div>
					</div>
				</div>
				<div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '300px', height:'auto' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={suraaj} alt='...' />
							<a href='https://www.linkedin.com/in/suraaj-malviya-573b9a257/' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Developer
							</h3>
							<h3 className='full-name'>Suraaj Malviya</h3>
						</div>
					</div>
				</div>
				{/* <div className='col-sm-4 col-lg-3 col-xl-3' style={{ width: '250px' }}>
					<div className='single-person' id='scroll-Nikhil'>
						<div className='person-image'>
							<img src={placeholder} alt='...' />
							<a href='' target='_blank' className='icon'>
								<i className='fa-brands fa-linkedin'></i>
							</a>
						</div>
						<div className='person-info text-center'>
							<h3 className='full-name' style={{ color: '#f63d3d' }}>
								Developer
							</h3>
							<h3 className='full-name'>Ajane Sambhaji</h3>
						</div>
					</div>
				</div> */}
			</div><br />



<div className='team-container'>
  <div className='advisory-panel'>
    {/* <h4>People</h4>
    <div className='managing-director'>
      <h5>Managing Director</h5>
      <div className='advisory-row'>
        <h6 className='text-start'>Deep Shikha</h6>
        <div className='img-container float-end ms-3 mb-3'>
          <img src={DeepShikha} alt='...' className='rounded-circle zoom-img normal-image' />
        </div>
        <p><span className='bold-name'></span> </p>
      </div>
    </div>
	<br /><br /> */}
    <h5 >Advisory Team</h5>
    <div className='advisory-row'>
      <h6 className='text-start' style={{textDecoration:"underline"}}>Dr. Lalan Kumar</h6>
      <div className='img-container float-end ms-3 mb-3' style={{position:"absolute", right:"70px", marginTop:"-60px"}}>
        <img src={lalanAdviser} alt='...' className='rounded-circle zoom-img normal-image' />
      </div>
      <div style={{marginRight:"200px",textAlign:"left"}}><span className='bold-name'>Prof. Lalan Kumar</span>   is an associate professor in the Electrical Engineering department of IIT Delhi. He is an alumnus of IIT BHU and IIT Kanpur. His research interest is signal processing.</div>
    </div>
	<br />
    <div className='advisory-row'>
      <h6 className='text-start' style={{textDecoration:"underline"}}>Saurabh Singh</h6>
      <div className='img-container float-end ms-3 mb-3' style={{position:"absolute", right:"70px", marginTop:"-45px"}}>
        <img src={SaurabhAdviser} alt='...' className='rounded-circle zoom-img chandan-saurabh' />
      </div>
      <div style={{marginRight:"200px", textAlign:"left"}}><span className='bold-name'>Mr. Saurabh Singh</span>  is working as director of credit at Trifecta Capital. He had been the co-founder of Intellinovate Solutions, Crispy Tokri, and Clove Dental. He is an alumnus of IIT BHU and IIM Ahmedabad.</div>
    </div>
	<br />
    <div className='advisory-row'>
      <h6 className='text-start' style={{textDecoration:"underline"}}>Chandan Kumar</h6>
      <div className='img-container float-end ms-3 mb-3' style={{position:"absolute", right:"70px", marginTop:"-30px"}}>
        <img src={ChandanAdviser} alt='...' className='rounded-circle zoom-img chandan-saurabh' />
      </div>
      <div style={{marginRight:"200px", textAlign:"left"}}><span className='bold-name'>Mr. Chandan Kumar</span> 
	  is working with Tata Steel as senior planning manager. He is an alumnus of IIT BHU and IIM Ahmedabad.</div>
    </div>
  </div>
</div>

			

			{/* About us section end */}


			
		
      <br />
      <br />
      <br />
			<br/>
			<Footer />
		</div>
	);
};

export default Home;
