import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../../compos/Header/Header';
import AllPosts from '../../compos/AllPosts/AllPosts';
import '../News/News.css';
import SinglePostHelper, { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import { LoginProps } from '../Login/Login';
import { useUser } from '../../UserContext';

function News() {
	const navigate = useNavigate();
	
	const {user,set_user, setGetPostId,setIsLoggedIn, setIsAdmin} = useUser();
	const checkIfAdmin = async (setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
		  const response = await fetch('https://backend.b-reporter.com/api/isAdmin', {
			method: 'GET',
			credentials: 'include',
		  });
	
		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }
	
		  const data = await response.json();
		  
	
		  // Check if the API response indicates the user is an admin
		  setIsAdmin(data);
		} catch (error) {
		  console.error('There was a problem with the fetch operation:', error);
		  setIsAdmin(false);
		}
	  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const profileRequestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Profile request failed');
          }
        })
        .then(result => {
          set_user(result);
          setIsLoggedIn(true);
          checkIfAdmin(setIsAdmin);
        })
        .catch(error => {
          console.log('Error occurred while fetching profile:', error);
          setIsLoggedIn(false);
          set_user(null);
        });
    } else {
      setIsLoggedIn(false);
      set_user(null);
    }
  }, [set_user, setIsLoggedIn, setIsAdmin]);
	
 
	return (
		<div className='News'>

			
			<SinglePostHelper  />
		</div>
	);
}

export default News;
