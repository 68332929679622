import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './FeatureSlider.css';
import CCTVImg from '../../Media/Images/Slide_CCTV.jpeg';
import PostFactoImg from '../../Media/Images/post-facto-feature-img.jpeg';
import Sensitive from '../../Media/Images/sensitive.jpg';
import People from '../../Media/Images/people.jpg';
import LadyWithWatch from '../../Media/Images/lady-with-watch-croam-img.jpg';

import RecordReport from '../../Media/Images/record-and-report-img.jpg';
import LocationAware from '../../Media/Images/locationAware.png';
import Riskometer from '../../Media/Images/riskometer_new_img.jpg';

const FeatureSlider = () => {
  
//to change the interval after which the image slides, just set the interval={time in miliseconds} in the carousel prop. 
//the default is interval={5000} ,i.e., 5 seconds.
 

 
  return (<>
  <div className='feature-slider-caption-container'>
     <Carousel indicators={false} controls={true} pause={false}>
      <Carousel.Item>
        <div className="d-flex full-width feature-slider-container">
          <div className="feature-image-container">
            <img
              className="d-block w-100"
              src={PostFactoImg}
              alt="First slide"
            />
            {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white'}}>
              <h3> No more Post Facto</h3>
              <p>Eliminating after-the-fact corrections ensuring timely and accurate Reporting.
              </p>
            </div> */}
          </div>
          
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex full-width">
         
          <div className="feature-image-container">
            <img
              className="d-block w-100"
              src={RecordReport}
              alt="Fourth slide"
            />
            {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white'}}>
              <h3>No More Fake News </h3>
              <p>Restoring integrity in Media</p>
            </div> */}
          </div>
        </div>
      </Carousel.Item>
      
    </Carousel>
    <div className={`feature-carousel-caption`} >
              <h3> No more Post-Facto</h3>
             
            </div>
    </div>

    <div className='feature-slider-caption-container'>

      <Carousel indicators={false} controls={true} pause={false}>
     <Carousel.Item>
       <div className="d-flex full-width feature-slider-container">
         <div className="feature-image-container">
           <img
             className="d-block w-100"
             src={LadyWithWatch}
             alt="First slide"
           />
           {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white'}}>
             <h3>Express Reporting with “OK Report” – Going Multimodal</h3>
             <p>Quick and easy one-click activation and de-activation of the feature.
             </p>
           </div> */}
         </div>
         
       </div>
     </Carousel.Item>
     <Carousel.Item>
       <div className="d-flex full-width">
        
         <div className="feature-image-container">
           <img
             className="d-block w-100"
             src={CCTVImg}
             alt="Fourth slide"
           />
           {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white', left:'5%', bottom:'40%', width:'40%'}}>
             <h3>Any where, Any Time</h3>
             <p>Report using Mobile and smart-wearables such as Smartwatches</p>
           </div> */}
         </div>
       </div>
     </Carousel.Item>
   </Carousel>
   <div className={`feature-carousel-caption`} >
              <h3>Express Reporting - Any Where, Any Time</h3>
             
            </div>
    </div>
    <div className='feature-slider-caption-container'>
     <Carousel indicators={false} controls={true} pause={false}>
    <Carousel.Item>
      <div className="d-flex full-width feature-slider-container">
        <div className="feature-image-container">
          <img
            className="d-block w-100"
            src={LocationAware}
            alt="First slide"
          />
          {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white'}}>
            <h3>Location Aware</h3>
            <p>Actual location based Reporting System</p>
          </div> */}
        </div>
        
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="d-flex full-width">
       
        <div className="feature-image-container">
          <img
            className="d-block w-100"
            src={Riskometer}
            alt="Fourth slide" />
          {/* <div className={`feature-carousel-caption ${animationClass}`} >
            <h3>Scene Aware</h3>
            <p>Analyzes and evaluates the potential risk of the situation</p>
          </div> */}
        </div>
      </div>
    </Carousel.Item>
  </Carousel>
  <div className={`feature-carousel-caption`} >
              <h3> Location Aware, Scene Aware</h3>
             
            </div>
    </div>
    <div className='feature-slider-caption-container'>
    <Carousel indicators={false} controls={true} pause={false}>
   <Carousel.Item>
     <div className="d-flex full-width feature-slider-container">
       <div className="feature-image-container">
         <img
           className="d-block w-100"
           src={Sensitive}
           alt="First slide"
         />
         {/* <div className={`feature-carousel-caption ${animationClass}`}>
           <h3>Being Sensitive</h3>
           <p>Enhances Safety and Security by facilitating direct community engagement</p>
         </div> */}
       </div>
       
     </div>
   </Carousel.Item>
   <Carousel.Item>
     <div className="d-flex full-width">
      
       <div className="feature-image-container">
         <img
           className="d-block w-100"
           src={People}
           alt="Fourth slide"
         />
         {/* <div className={`feature-carousel-caption ${animationClass}`} style={{color:'white'}}>
           <h3>People centric</h3>
           <p>Experience news that puts the community first</p>
         </div> */}
       </div>
     </div>
   </Carousel.Item>
 </Carousel>
 <div className={`feature-carousel-caption`} >
              <h3>People Centric</h3>
             
            </div>
    </div>
 </>
  );
};

export default FeatureSlider;
